<template>
	<div class="my-account-form">
		<div class="my-account-form__header">
			<v-btn class="my-account-form__header-button" icon color="#575E60" @click="frompage ? $router.back() : $router.push({ name: 'Home' })">
				<v-icon class="my-account-form__header-button-icon">far fa-arrow-left</v-icon>
			</v-btn>
			<h2 class="my-account-form__header-title">{{ $t('account.myAccount') }}</h2>
		</div>
		<div class="my-account-form__body">
			<v-expansion-panels v-if="userInfo" accordion multiple flat v-model="extended">
				<v-expansion-panel class="my-account-form__body-extensible" v-model="extended">
					<v-expansion-panel-header>
						<template v-slot:actions><v-icon :style="colorStyle">$expand</v-icon></template>
						{{ $t('account.personalData.title') }}
						<div style="padding-right: 0.5rem; text-align: end">
							<span class="my-account-form__body-extensible-text collapsed" :style="colorStyle">{{ $t('account.expandForm') }}</span>
							<span class="my-account-form__body-extensible-text extended">{{ $t('account.collapseForm') }}</span>
						</div>
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<form>
							<div class="my-account-form__body-section">
								<span class="my-account-form__body-section-title">{{ $t('account.personalData.personalInformation') }}</span>
								<div class="my-account-form__body-section-container">
									<v-text-field
										class="my-account-form__input"
										:label="$t('account.personalData.user')"
										v-model="userInfo.usr"
										:color="primaryColor"
										readonly
										required
									></v-text-field>
									<v-text-field
										class="my-account-form__input"
										:label="$t('account.personalData.name')"
										v-model="userInfo.name"
										:color="primaryColor"
										required
									></v-text-field>
									<v-text-field
										class="my-account-form__input"
										:label="$t('account.personalData.surname')"
										v-model="userInfo.usfvsurname"
										:color="primaryColor"
									></v-text-field>
									<Select
										class="my-account-form__input"
										:label="$t('account.personalData.documentType')"
										v-model="userInfo.usfvdocumenttype"
										:items="['NIF', 'NIE', 'Pasaporte']"
									/>
									<v-text-field
										class="my-account-form__input"
										:label="$t('account.personalData.documentNumber')"
										v-model="userInfo.usfvdocument"
										:color="primaryColor"
									></v-text-field>
								</div>
							</div>
							<div class="my-account-form__body-section">
								<span class="my-account-form__body-section-title">{{ $t('account.postalAddress') }}</span>
								<div class="my-account-form__body-section-container">
									<v-text-field
										class="my-account-form__input"
										:label="$t('account.personalData.address1')"
										v-model="userInfo.usfvaddress1"
										:color="primaryColor"
									></v-text-field>
									<v-text-field
										class="my-account-form__input"
										:label="$t('account.personalData.address2')"
										v-model="userInfo.usfvaddress2"
										:color="primaryColor"
									></v-text-field>
									<v-text-field
										class="my-account-form__input"
										:label="$t('account.city')"
										v-model="userInfo.usfvcity"
										:color="primaryColor"
									></v-text-field>
									<v-text-field
										class="my-account-form__input"
										:label="$t('account.postalAddress')"
										v-model="userInfo.usfvcp"
										:color="primaryColor"
									></v-text-field>
									<Select
										class="my-account-form__input"
										:label="$t('account.country')"
										v-model="userInfo.usfvcountry"
										:items="countries"
										item-text="cotrname"
										item-value="counisocode"
									/>

									<Select
										class="my-account-form__input"
										:label="$t('account.province')"
										v-model="userInfo.usfvprovince"
										:items="provinces"
										item-text="prtrname"
										item-value="provcode"
									/>
								</div>
							</div>
							<div class="my-account-form__body-section">
								<span class="my-account-form__body-section-title">{{ $t('account.contact') }}</span>
								<div class="my-account-form__body-section-container">
									<v-text-field
										class="my-account-form__input"
										:label="$t('account.telephone')"
										v-model="userInfo.usfvphone"
										:color="primaryColor"
									></v-text-field>
									<v-text-field
										class="my-account-form__input"
										:label="$t('account.personalData.email')"
										v-model="userInfo.email"
										:color="primaryColor"
									></v-text-field>
								</div>
							</div>
							<div class="my-account-form__body-section">
								<span class="my-account-form__body-section-title">{{ $t('account.configuration') }}</span>
								<div class="my-account-form__body-section-container">
									<Select
										class="my-account-form__input"
										:label="$t('account.personalData.language')"
										v-model="userInfo.language"
										:items="languages"
										item-text="name"
										item-value="isocode"
										required
									/>
									<Select
										class="my-account-form__input"
										:label="$t('account.personalData.dateFormat')"
										v-model="userInfo.dateformat"
										:items="['dd-MM-yyyy', 'dd/MM/yyyy', 'yyyy-MM-dd', 'yyyy/MM/dd']"
										required
									/>
								</div>
							</div>
							<div class="my-account-form__body-section" style="padding-bottom: 2rem">
								<span class="my-account-form__body-section-title">{{ $t('account.personalData.RGPD') }}</span>
								<div class="my-account-form__body-section-container">
									<!-- <v-checkbox
										class="my-account-form__input"
										v-model="userInfo.visiincludedata"
										:label="$t('account.personalData.includeData')"
									></v-checkbox>
									<v-checkbox
										class="my-account-form__input"
										v-model="userInfo.visidatathirdparties"
										:label="$t('account.personalData.thirdPartiesData')"
									></v-checkbox> -->
									<v-checkbox
										class="my-account-form__input"
										v-model="userInfo.usfvreceiveinformation"
										:label="$t('account.personalData.receiveInformation')"
									></v-checkbox>
								</div>
							</div>
						</form>
						<v-form ref="form" v-model="valid" lazy-validation @submit.prevent="changePassword()">
							<div class="my-account-form__body-section" style="border-top: 1px solid #d2d7d9">
								<span class="my-account-form__body-section-title">{{ $t('account.personalData.changePassword') }}</span>
								<div class="my-account-form__body-section-container">
									<v-text-field
										class="my-account-form__input"
										:label="$t('account.personalData.currentPassword')"
										v-model="currentPassword"
										:type="show1 ? 'text' : 'password'"
										:color="primaryColor"
										:append-icon="show1 ? 'visibility' : 'visibility_off'"
										@click:append="show1 = !show1"
										required
									></v-text-field>
									<v-text-field
										class="my-account-form__input"
										:label="$t('account.personalData.newPassword')"
										v-model="newPassword"
										:type="show2 ? 'text' : 'password'"
										:color="primaryColor"
										:append-icon="show2 ? 'visibility' : 'visibility_off'"
										@click:append="show2 = !show2"
										required
									></v-text-field>
									<v-text-field
										class="my-account-form__input"
										:label="$t('account.personalData.confirmPassword')"
										v-model="confirmPassword"
										:rules="[newPassword === confirmPassword || $t('account.personalData.notMatch')]"
										:type="show3 ? 'text' : 'password'"
										:color="primaryColor"
										:append-icon="show3 ? 'visibility' : 'visibility_off'"
										@click:append="show3 = !show3"
										required
									></v-text-field>
									<div style="width: 48%; display: flex; justify-content: center; padding-top: 0.5rem">
										<v-btn depressed color="#2498ff" style="color: #fff" type="submit">
											{{ $t('account.personalData.changePassword') }}
										</v-btn>
									</div>
								</div>
							</div>
						</v-form>
					</v-expansion-panel-content>
				</v-expansion-panel>
				<v-expansion-panel v-if="userInfo.companyData" class="my-account-form__body-extensible" style="margin-top: 1rem" v-model="extended">
					<v-expansion-panel-header>
						<template v-slot:actions><v-icon :style="colorStyle">$expand</v-icon></template>
						{{ $t('account.companyData.title') }}
						<div style="padding-right: 0.5rem; text-align: end">
							<span class="my-account-form__body-extensible-text collapsed" :style="colorStyle">{{ $t('account.expandForm') }}</span>
							<span class="my-account-form__body-extensible-text extended">{{ $t('account.collapseForm') }}</span>
						</div>
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<form>
							<div class="my-account-form__body-section">
								<span class="my-account-form__body-section-title"> {{ $t('account.companyData.companyInformation') }} </span>
								<div class="my-account-form__body-section-container">
									<v-text-field
										class="my-account-form__input"
										:label="$t('account.companyData.company')"
										v-model="userInfo.companyData.viconame"
										:color="primaryColor"
									></v-text-field>
									<v-text-field
										class="my-account-form__input"
										:label="$t('account.companyData.CIF')"
										v-model="userInfo.companyData.vicocif"
										:color="primaryColor"
									></v-text-field>
								</div>
							</div>
							<div class="my-account-form__body-section">
								<span class="my-account-form__body-section-title">{{ $t('account.companyData.position') }}</span>
								<div class="my-account-form__body-section-container">
									<v-text-field
										class="my-account-form__input"
										:label="$t('account.companyData.title/position')"
										v-model="userInfo.companyData.vicoposition"
										:color="primaryColor"
									></v-text-field>
									<v-text-field
										class="my-account-form__input"
										:label="$t('account.companyData.department')"
										v-model="userInfo.companyData.vicodepartment"
										:color="primaryColor"
									></v-text-field>
									<v-text-field
										class="my-account-form__input"
										:label="$t('account.companyData.firm')"
										v-model="userInfo.companyData.vicofirm"
										:color="primaryColor"
									></v-text-field>
								</div>
							</div>
							<div class="my-account-form__body-section">
								<span class="my-account-form__body-section-title">{{ $t('account.postalAddress') }}</span>
								<div class="my-account-form__body-section-container">
									<v-text-field
										class="my-account-form__input"
										:label="$t('account.companyData.address')"
										v-model="userInfo.companyData.vicoaddress"
										:color="primaryColor"
									></v-text-field>
									<v-text-field
										class="my-account-form__input"
										:label="$t('account.city')"
										v-model="userInfo.companyData.vicocity"
										:color="primaryColor"
									></v-text-field>
									<v-text-field
										class="my-account-form__input"
										:label="$t('account.companyData.postalCode')"
										v-model="userInfo.companyData.vicocp"
										:color="primaryColor"
									></v-text-field>
									<Select
										class="my-account-form__input"
										:label="$t('account.country')"
										v-model="userInfo.companyData.country"
										:items="countries"
										item-text="cotrname"
										item-value="counisocode"
									/>
									<Select
										class="my-account-form__input"
										:label="$t('account.province')"
										v-model="userInfo.companyData.province"
										:items="provinces"
										item-text="prtrname"
										item-value="provcode"
									/>
								</div>
							</div>
							<div class="my-account-form__body-section">
								<span class="my-account-form__body-section-title">{{ $t('account.contact') }}</span>
								<div class="my-account-form__body-section-container">
									<v-text-field
										class="my-account-form__input"
										:label="$t('account.telephone')"
										v-model="userInfo.companyData.vicophone"
										:color="primaryColor"
									></v-text-field>
									<v-text-field
										class="my-account-form__input"
										:label="$t('account.companyData.website')"
										v-model="userInfo.companyData.vicoweb"
										:color="primaryColor"
									></v-text-field>
								</div>
							</div>
						</form>
					</v-expansion-panel-content>
				</v-expansion-panel>
				<div class="my-account-form__submit" @click="updateAccount()">
					<v-btn depressed color="#2498ff" style="color: #fff"> {{ $t('account.save') }} </v-btn>
				</div>
			</v-expansion-panels>
		</div>
	</div>
</template>

<script>
import { getVisitorInfo, updateVisitorInfo } from '@/api/user.js';
import { changePassword } from '@/api/sessions.js';
import { search } from '@/api/common.js';
import Select from '@/components/common/Select.vue';

export default {
	name: 'MyAccountForm',
	props: {
		primaryColor: {
			type: String,
			default: '#d4af37'
		},
		frompage: Boolean
	},
	components: { Select },
	data() {
		return {
			userInfo: null,
			countries: null,
			provinces: null,
			languages: null,
			extended: [0],
			colorStyle: {
				'--primaryColor': this.primaryColor
			},
			currentPassword: null,
			newPassword: null,
			confirmPassword: null,
			show1: false,
			show2: false,
			show3: false,
			valid: true
		};
	},

	mounted() {
		this.getInfo();
		this.getCountries();
		this.getProvinces();
		this.getLanguages();
	},

	methods: {
		getInfo: async function () {
			if (localStorage.getItem('pui9_session')) {
				const user = JSON.parse(localStorage.getItem('pui9_session'));
				const email = user.usr;
				this.userInfo = await getVisitorInfo(email);
			}
		},

		getCountries: async function () {
			const body = {
				filter: null,
				model: 'country',
				order: [
					{
						column: 'cotrname',
						direction: 'asc'
					}
				],
				page: 1,
				queryFields: ['counisocode', 'cotrname', 'lang'],
				queryText: '',
				rows: 999999999
			};

			const data = await search(body);
			this.countries = data.data;
		},

		getProvinces: async function () {
			const body = {
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							data: 'ES',
							field: 'provcountryid',
							op: 'eq'
						}
					]
				},
				model: 'province',
				order: [
					{
						column: 'cotrname',
						direction: 'asc'
					}
				],
				page: 1,
				queryFields: ['provcountryid', 'provcode', 'provpref', 'cotrname', 'prtrname', 'lang'],
				queryText: '',
				rows: 999999999
			};

			const data = await search(body);
			this.provinces = data.data;
		},

		getLanguages: async function () {
			const body = {
				filter: null,
				model: 'puilanguage',
				order: [
					{
						column: 'name',
						direction: 'asc'
					}
				],
				page: 1,
				queryFields: ['isocode', 'name', 'isdefault'],
				queryText: '',
				rows: 999999999
			};

			const data = await search(body);
			this.languages = data.data;
		},

		updateAccount: async function () {
			if (this.userInfo.visiincludedata === true) this.userInfo.visiincludedata = 1;
			else if (this.userInfo.visiincludedata === false) this.userInfo.visiincludedata = 0;
			if (this.userInfo.visidatathirdparties === true) this.userInfo.visidatathirdparties = 1;
			else if (this.userInfo.visidatathirdparties === false) this.userInfo.visidatathirdparties = 0;
			if (this.userInfo.usfvreceiveinformation === true) this.userInfo.usfvreceiveinformation = 1;
			else if (this.userInfo.usfvreceiveinformation === false) this.userInfo.usfvreceiveinformation = 0;
			this.userInfo.visireceiveinformation = this.userInfo.usfvreceiveinformation;
			this.userInfo.companyData.vicocountry = this.userInfo.companyData.country;
			this.userInfo.companyData.vicoprovince = this.userInfo.companyData.province;

			this.userInfo.companyData.country = {};
			this.userInfo.companyData.province = {};
			const data = await updateVisitorInfo(this.userInfo);
			data.responseType === 'success'
				? this.$puiNotify.success(this.$t('account.updateAccountSuccess'), this.$t('account.saved'))
				: this.$puiNotify.error(this.$t('account.updateAccountError'), this.$t('account.error'));

			this.userInfo.companyData.country = this.userInfo.companyData.vicocountry;
			this.userInfo.companyData.province = this.userInfo.companyData.vicoprovince;
		},

		changePassword: async function () {
			if (this.$refs.form.validate()) {
				const data = await changePassword(this.currentPassword, this.newPassword);
				data.statusText === 'OK'
					? this.$puiNotify.success(this.$t('account.changePasswordSuccess'), this.$t('account.saved'))
					: this.$puiNotify.error(this.$t('account.changePasswordError'), this.$t('account.error'));
			}
		}
	}
};
</script>

<style lang="scss">
.my-account-form {
	background: #fff;
	border-radius: 12px;
	padding: 2rem;

	&__input {
		width: 40%;
		max-width: 48%;

		& > .v-input__control > .v-input__slot > .v-text-field__slot > .v-label {
			font-size: 14px;
			margin-top: 0.5rem;
		}
	}

	&__header {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;

		&-button {
			position: absolute;
			left: 0;
			width: 1rem;
			height: 1rem;

			&-icon {
				font-size: 1rem !important;
			}
		}

		&-title {
			font-family: Cabin;
			font-weight: bold;
			font-size: 1.625rem;
			color: #293133;
		}
	}

	&__body {
		padding-top: 1.5rem;

		&-extensible {
			border: 1px solid #d2d7d9;
			border-radius: 8px !important;

			& > .v-expansion-panel-header--active,
			& > .v-expansion-panel-content {
				background-color: #fff !important;
			}

			& > .v-expansion-panel-header {
				font-family: Cabin;
				font-weight: bold;
				font-size: 16px;
				color: #293133;
			}

			&-text {
				font-family: Cabin;
				font-weight: 500;
				font-size: 14px;

				&.collapsed {
					color: var(--primaryColor);
				}

				&.extended {
					color: #868c8d;
				}
			}

			& > .v-expansion-panel-header--active > div > .my-account-form__body-extensible-text.collapsed {
				display: none;
			}

			& > .v-expansion-panel-header:not(.v-expansion-panel-header--active) > div > .my-account-form__body-extensible-text.extended {
				display: none;
			}

			& > .v-expansion-panel-header > .v-expansion-panel-header__icon > .v-icon {
				font-size: 0.75rem;
			}

			& > .v-expansion-panel-header:not(.v-expansion-panel-header--active) > .v-expansion-panel-header__icon > .v-icon {
				color: var(--primaryColor);
			}
		}

		&-section {
			padding-left: 1rem;
			padding-top: 1rem;
			&-title {
				font-weight: 500;
				font-size: 14px;
				color: #575e60;
			}

			&-container {
				padding-top: 1.25rem;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
			}
		}
	}

	&__submit {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		padding-top: 2rem;
	}
}
</style>
